





























































import { Component, Vue } from 'vue-property-decorator'
import Spinner from '@/components/atoms/Spinner.vue'
import organizationsStore, { ErrorMsg } from '@/store/Organizations'
import messagesStore from '@/store/Messages'

@Component({ components: { Spinner } })
export default class extends Vue {
  orgName = ''
  phoneNumber = ''
  payMethod = 'credit'
  isLoading = false
  error = ''
  async regist(): Promise<void> {
    this.isLoading = true
    try {
      await organizationsStore.regist({ name: this.orgName, phoneNumber: this.phoneNumber, payMethod: this.payMethod })
      localStorage.setItem('firstTimeFlag', 'true')
      this.$router.replace({ name: 'AccountRegistrationComplete' })
    } catch (e) {
      const message = '登録に失敗しました。しばらく時間をおいて再度お試しください。'
      if (e === ErrorMsg.responseError(401)) {
        messagesStore.addMessage({ key: 'AccountRegister', message })
        this.$router.replace({ name: 'UserAuthentication' })
        return
      }
      this.error = message
    } finally {
      this.isLoading = false
    }
  }
}
