import store from '@/store'
import { Module, VuexModule, Action, getModule, Mutation } from 'vuex-module-decorators'
import axios, { AxiosError } from 'axios'
import config from '@/config'

export const ErrorMsg = {
  responseError: (status: number) => 'API response error status:' + status,
  CurrentOrganizationFailureError: '現在の組織情報を取得できませんでした',
} as const

export type Organization = {
  id: string
  name: string
}

@Module({ dynamic: true, namespaced: true, name: 'organizations', store })
class Mod extends VuexModule {
  currentOrganization: Organization | null = null
  isRemindable = false

  @Mutation
  setCurrentOrganization(currentOrganization: Organization): void {
    this.currentOrganization = currentOrganization
  }

  @Mutation
  setIsRemindable(isRemindable: boolean): void {
    this.isRemindable = isRemindable
  }

  @Action({ rawError: true })
  async regist({
    name,
    phoneNumber,
    payMethod,
  }: {
    name: string
    phoneNumber: string
    payMethod: string
  }): Promise<void> {
    const url = `${config.enterprise.apiUrl}/organizations/register`
    try {
      await axios.post(url, { name, phoneNumber, payMethod })
    } catch (e) {
      throw ErrorMsg.responseError((e as AxiosError).response?.status || 500)
    }
  }

  @Action({ commit: 'setCurrentOrganization', rawError: true })
  async fetchCurrentOrganization(): Promise<Organization> {
    const url = `${config.enterprise.apiUrl}/no-contract/organizations/current`
    try {
      const res = await axios.get(url)
      return res.data
    } catch (e) {
      throw new Error(ErrorMsg.CurrentOrganizationFailureError)
    }
  }

  @Action({ commit: 'setIsRemindable', rawError: true })
  async checkRemindStatus(): Promise<void> {
    const url = `${config.enterprise.apiUrl}/organizations/remind/status`
    try {
      const res = await axios.get(url)
      return res.data.isRemindable
    } catch (e) {
      throw ErrorMsg.responseError((e as AxiosError).response?.status || 500)
    }
  }
}

export default getModule(Mod)
