import store from '@/store'
import { Module, VuexModule, Mutation, getModule } from 'vuex-module-decorators'

/** フラッシュメッセージ用ストア */
@Module({ dynamic: true, namespaced: true, name: 'messages', store })
class Mod extends VuexModule {
  messages: { [key: string]: string } = {}

  @Mutation
  addMessage({ key, message }: { key: string; message: string }) {
    const timeout = 3000
    this.messages[key] = message
    setTimeout(() => delete this.messages[key], timeout)
  }
}

export default getModule(Mod)
