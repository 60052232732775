




import { Component, Vue } from 'vue-property-decorator'
import menuStore from '@/store/Menu'
import AccountRegistrationForm from '@/components/organisms/AccountRegistration/AccountRegistrationForm.vue'

@Component({ components: { AccountRegistrationForm } })
export default class extends Vue {
  mounted(): void {
    menuStore.setInvisible()
  }
}
